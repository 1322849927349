.container {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  width: inherit;
  height: inherit;
  /* margin-top: 60px; */
}

.contentOuterContainer {
  display: flex;
  height: inherit;
  /* left: 477px; */
  position: absolute;
  top: 0;
  width: -webkit-fill-available;
}

.fillHeight {
  height: -webkit-fill-available;
}

.contentInnerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 60px;
}

.textContainer {
  color: #000000;
  font-family: var(--menu-title-font-family);
  font-size: var(--menu-title-font-size);
  font-style: var(--menu-title-font-style);
  font-weight: var(--menu-title-font-weight);
  height: 22px;
  /* left: 420px; */
  letter-spacing: var(--menu-title-letter-spacing);
  line-height: var(--menu-title-line-height);
  /* position: absolute; */
  top: 19px;
  white-space: nowrap;
  height: 60px;
  border-bottom: 1px black solid;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.gridContainer {
  display: flex;
  flex-direction: column;
  height: calc(100% - 163px);
  width: 100%;
}

.gridInnerContainer {
  height: 100%;
  width: 100%;
}

.Closed {
  color: white;
  border-radius: 12px;
  padding: 0px 10px;
  background-color: black;
  width: fit-content;
  margin: auto;
}
.Modified {
  color: var(--button-click);
  border: 1px solid var(--button-click);
  border-radius: 12px;
  padding: 0px 10px;
  width: fit-content;
  margin: auto;
}

.New {
  color: white;
  background-color: var(--main-color);
  border-radius: 12px;
  width: fit-content;
  padding: 0px 10px;
  margin: auto;
}

.Waiting {
  color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 12px;
  width: fit-content;
  padding: 0px 10px;
  margin: auto;
}
